import React from 'react'
import './footer.css';

const TreadsIcon = () => {
  return (

        
<svg className='footer-social-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 448"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M0,64V384a64,64,0,0,0,64,64H384a64,64,0,0,0,64-64V64A64,64,0,0,0,384,0H64A64,64,0,0,0,0,64ZM293.5,213c19.44,9.27,33.59,23.42,41.07,40.77,10.36,24.22,11.36,63.69-20.14,95.08-24.12,24-53.32,34.89-94.79,35.19h-.2c-46.64-.3-82.53-16.05-106.55-46.65C91.46,310.14,80.4,272.17,80,224.62v.06c.4-47.93,11.56-86.12,33.16-113.65,24.32-30.85,60.49-46.73,107.42-47h.2c47.13.3,83.71,16.08,108.73,46.83,12.36,15.17,21.4,33.46,27.13,54.66l-27,7.24c-4.72-17.29-12-32.06-21.5-43.81-19.5-24-48.94-36.28-87.43-36.58-38.18.3-67.12,12.56-85.91,36.37-17.59,22.41-26.73,54.67-27,96,.31,41.3,9.45,73.66,27,96,18.79,23.91,47.63,36.17,85.91,36.37,34.47-.3,57.18-8.44,76.17-27.43,21.61-21.61,21.21-48.13,14.27-64.31-4-9.45-11.45-17.39-21.4-23.42-2.41,18.09-7.94,32.36-16.58,43.41-11.46,14.57-27.84,22.51-48.64,23.62-15.78.9-30.95-2.92-42.81-10.75-14-9.25-22.1-23.32-23-39.7-1.71-32.35,23.92-55.57,63.81-57.88a175.9,175.9,0,0,1,39.69,1.91c-1.6-9.95-4.92-17.78-9.84-23.51-6.74-7.84-17.19-11.86-31-12h-1.14c-10.89,0-25.73,3.07-35.23,17.41l-22.75-15.63C174.93,149.59,195.51,139,220.15,139h.19c41.67.3,66.39,26.21,68.88,71.57,1.39.59,2.79,1.19,4.18,1.89l.1.5ZM222.66,280.2c17-.9,36.4-7.6,39.7-48.8a138.34,138.34,0,0,0-29-2.9q-4.8,0-9.6.3c-28.6,1.6-38.1,15.5-37.4,27.9.9,16.7,19,24.5,36.4,23.6l-.1-.1Z"/></g></g></svg>
        

    
  )
}

export default TreadsIcon
//live
import React, { useRef, useState,useEffect, Suspense } from 'react';
import './servicesAnim.css';
import { Canvas, useFrame } from '@react-three/fiber';
import { Float, PerspectiveCamera, Preload} from '@react-three/drei';

import * as THREE from 'three';


const sharedPlane2Geometry = new THREE.PlaneGeometry(2,2,1,1);
const sharedPlaneGeometry = new THREE.PlaneGeometry(2, 2, 6, 6);

const GeometryDOWNs = ({ color }) => (

  
  
  <mesh  geometry={sharedPlane2Geometry} position={[0, -0.5, 0]} rotation={[-Math.PI / 2, 0, 0]}>
    
    <meshBasicMaterial side={THREE.DoubleSide} color={color} transparent opacity={0.7} />
  </mesh>
);

const GeometryUPs = ({ color }) => (
  
  <mesh geometry={sharedPlane2Geometry}  position={[0, 0.5, 0]} rotation={[-Math.PI / 2, 0, 0]}>
     
    <meshBasicMaterial side={THREE.DoubleSide} color={color} transparent opacity={0.7} />
  </mesh>
);

const GeometryMOVEwf = ({ onPositionChange }) => {


  const geometryRef = useRef();
  useFrame(({ clock }) => {
    const time = clock.getElapsedTime();
    
    const newY = Math.sin(time * 0.2) * 0.5;
    onPositionChange(newY);
    geometryRef.current.position.setY(newY);
  });
  
  return (
    <group ref={geometryRef}>
      <mesh geometry={sharedPlaneGeometry} rotation={[-Math.PI / 2, 0, 0]}  >
        <meshBasicMaterial color="#0072c3" wireframe />
      </mesh>
    </group>
  );
};


const GeometryMOVE2wf = ({ onPositionChange }) => {
  const geometryRef = useRef();

  useFrame(({ clock }) => {
    const time = clock.getElapsedTime();
    
    const newY = -Math.sin(time * 0.2) * 0.5;
    onPositionChange(newY);
    geometryRef.current.position.setY(newY);
  });
  
  return (
    <group ref={geometryRef}>
      <mesh geometry={sharedPlaneGeometry} rotation={[-Math.PI / 2, 0, 0]}>
        <meshBasicMaterial color="#0072c3" wireframe />
      </mesh>
    </group>
  );
};


function DevelopmentSceneOpt2() {

  const [positionY, setPositionY] = useState(0);
  const handlePositionChange = (newY) => {
    setPositionY(newY);
  };

  ////////////////////////////////////////
   // Definisanje stanja za dimenzije Canvas-a
  const [canvasDimensions, setCanvasDimensions] = useState({ width: 300, height: 255 });
   const [fov, setFov] = useState(85); // Default vrednost

  useEffect(() => {
    // Funkcija koja ažurira dimenzije Canvas-a na osnovu širine ekrana
    const updateCanvasDimensions = () => {
      const width = window.innerWidth;
      let newCanvasWidth = 300;
      let newCanvasHeight = 255;

      if (width < 350) { // Primer: Ako je širina ekrana manja od 992px
        newCanvasWidth = 250; // Promenite ove vrednosti kako želite
        newCanvasHeight = 245; // Promenite ove vrednosti kako želite
      }

      setCanvasDimensions({ width: newCanvasWidth, height: newCanvasHeight });
    };

    // Prvi put pozivamo funkciju kad se komponenta mountuje
    updateCanvasDimensions();
    
    // Dodavanje event listenera koji će pozivati funkciju svaki put kad se promeni veličina ekrana
    window.addEventListener('resize', updateCanvasDimensions);
    
    // Uklanjanje event listenera kad se komponenta unmountuje
    return () => window.removeEventListener('resize', updateCanvasDimensions);
  }, []);

  useEffect(() => {
    // Funkcija koja ažurira fov na osnovu širine ekrana
    const updateFov = () => {
      const width = window.innerWidth;
      let newFov = 85; // Default vrednost
      
      if (width < 350) { // Primer: Ako je širina ekrana manja od 350px
        newFov = 93; 
      } else if (width < 992) { // Ako je širina ekrana između 768px i 992px
        newFov = 89;
      }

      setFov(newFov);
    };

    // Prvi put pozivamo funkciju kad se komponenta mountuje
    updateFov();
    
    // Dodavanje event listenera koji će pozivati funkciju svaki put kad se promeni veličina ekrana
    window.addEventListener('resize', updateFov);
    
    // Uklanjanje event listenera kad se komponenta unmountuje
    return () => window.removeEventListener('resize', updateFov);
  }, []);
  

  ///////////////////////////////////////

  // const isSmallScreen = window.innerWidth < 992; 
  // const fov = isSmallScreen ? 89 : 85; 


    let colorSolid = positionY >= 0.45 || positionY <= -0.45 ? "#0072c3" : "#8a3ffc";
  

  return (

    <div className='servicesAnim_canvas_wrapper'>
     {/* */}
     
       <Canvas  pixelRatio={2} style={{ width: canvasDimensions.width, height: canvasDimensions.height}} invalidateFrameloop>
      
      
      <ambientLight color={0xffffff} intensity={1} />
      <directionalLight color={0xffffff} position={[0, 0, 2]} intensity={1} />
      <PerspectiveCamera makeDefault fov={fov} position={[0, 0, 2]} near={0.1} far={1000} />
      <Suspense fallback={null}>
        <Float>
          
          <GeometryDOWNs  color={colorSolid}/>
          <GeometryUPs  color={colorSolid}/>
          <GeometryMOVEwf onPositionChange={handlePositionChange} />
          <GeometryMOVE2wf onPositionChange={handlePositionChange} />
          
        </Float>
      </Suspense>
      <Preload all />
    </Canvas>
     
    </div>
   
   
   

  );
}

export default DevelopmentSceneOpt2;

import React from 'react'
import './news.css'

import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';

import dataNews from './dataNews';
import { NewsCard } from './NewsCard';

const News = () => {
  const cards = dataNews.map(card =>{

  return <NewsCard 
  img={card.img} 
  date={card.date} 
  topic={card.topic} 
   content={card.content} 
   link={card.link} 
   key={card.id} />

 })
  const options = {
    items: 4,
    center: false,
    dots: false,
    nav: true,
    navText: ["", ""],
    loop: false,
    margin: 16,
    responsive: {
      0: {
        items: 1,
        margin: 12,
        stagePadding: 30,
         nav: false,
      },
      576: {
        items: 1,
        margin: 12,
        stagePadding: 30,
        nav: false,
      },
      768: {
        items: 2,
        margin: 12,
        stagePadding: 30,
      },
      992: {
        items: 3,
        margin: 16,
        stagePadding: 40,
      },
      1200: {
        items: 3,
        margin: 16,
        stagePadding: 50,
      },
      1400: {
        items: 4,
        margin: 16,
      },
    },
  };

  return (
    <section id='news'>
      <div className='container news-main'>

         <div className="row news-content">
                    <div className="col-lg-12 col-md-12 col-sm-12 news-title">
                         <h2>News</h2>
                    </div>
            
                </div>
                <div className='news-news'>
                     <OwlCarousel className='news-owl owl-theme' {...options}>
         
                        {cards}
                      </OwlCarousel>

                </div>
       
    </div>

    </section>
    
  )
}

export default News
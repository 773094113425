//live
import React, { useRef,useState,useEffect, useMemo, Suspense } from 'react';
import './servicesAnim.css';
import { Canvas, useFrame } from '@react-three/fiber';
import { PerspectiveCamera, Float, Preload } from '@react-three/drei';
import * as THREE from 'three';

const CloneWf = React.memo(({ coneGeometryWf }) => {

  const geometryRef = useRef();
  useFrame((state, delta) => {
    if (geometryRef.current && state.clock.elapsedTime % 0.03 < delta) {
      geometryRef.current.rotation.x += 0.005;
    }
    
  });

  return (
    <group ref={geometryRef}>
      <mesh geometry={coneGeometryWf} position={[0.7, 0, 0]} rotation={[Math.PI / 2, 0, -Math.PI / 2]}>
        <meshBasicMaterial color="#0072C3" wireframe />
      </mesh>
    </group>
  );
});

const CloneS = React.memo(({ coneGeometryS }) => {

  const geometryRef = useRef();
  useFrame((state, delta) => {
    if (geometryRef.current && state.clock.elapsedTime % 0.03 < delta) {
      geometryRef.current.rotation.x -= 0.005;
    }
    
  });

  return (
    <group ref={geometryRef}>
      <mesh geometry={coneGeometryS} position={[-0.7, 0, 0]} rotation={[Math.PI / 2, 0, -Math.PI * 1.5]}>
        <meshPhongMaterial color="#8a3ffc" />
      </mesh>
    </group>
  );
});

function ConsultingSceneOpt2() {
  const coneGeometryWf = useMemo(() => new THREE.ConeGeometry(1, 1, 4, 5), []);
  const coneGeometryS = useMemo(() => new THREE.ConeGeometry(1, 1, 4,1), []);

  ////////////////////////////////////////
   // Definisanje stanja za dimenzije Canvas-a
  const [canvasDimensions, setCanvasDimensions] = useState({ width: 300, height: 255 });
  const [fov, setFov] = useState(85); // Default vrednost

  useEffect(() => {
    // Funkcija koja ažurira dimenzije Canvas-a na osnovu širine ekrana
    const updateCanvasDimensions = () => {
      const width = window.innerWidth;
      let newCanvasWidth = 300;
      let newCanvasHeight = 255;

      if (width < 350) { // Primer: Ako je širina ekrana manja od 992px
        newCanvasWidth = 250; // Promenite ove vrednosti kako želite
        newCanvasHeight = 245; // Promenite ove vrednosti kako želite
      }

      setCanvasDimensions({ width: newCanvasWidth, height: newCanvasHeight });
    };

    // Prvi put pozivamo funkciju kad se komponenta mountuje
    updateCanvasDimensions();
    
    // Dodavanje event listenera koji će pozivati funkciju svaki put kad se promeni veličina ekrana
    window.addEventListener('resize', updateCanvasDimensions);
    
    // Uklanjanje event listenera kad se komponenta unmountuje
    return () => window.removeEventListener('resize', updateCanvasDimensions);
  }, []);


  useEffect(() => {
    // Funkcija koja ažurira fov na osnovu širine ekrana
    const updateFov = () => {
      const width = window.innerWidth;
      let newFov = 80; // Default vrednost
      
      if (width < 350) { // Primer: Ako je širina ekrana manja od 350px
        newFov = 88; 
      } else if (width < 992) { // Ako je širina ekrana između 768px i 992px
        newFov = 84;
      }

      setFov(newFov);
    };

    // Prvi put pozivamo funkciju kad se komponenta mountuje
    updateFov();
    
    // Dodavanje event listenera koji će pozivati funkciju svaki put kad se promeni veličina ekrana
    window.addEventListener('resize', updateFov);
    
    // Uklanjanje event listenera kad se komponenta unmountuje
    return () => window.removeEventListener('resize', updateFov);
  }, []);

  ///////////////////////////////////////

  // const isSmallScreen = window.innerWidth < 992; 
  // const fov = isSmallScreen ? 84 : 80; 

  return (
    <div className='servicesAnim_canvas_wrapper'>
      {/*  */}
<Canvas  pixelRatio={2} style={{ width: canvasDimensions.width, height: canvasDimensions.height }}  invalidateFrameloop>

      <ambientLight color={0xffffff} intensity={1} />
     
      <directionalLight color={0xffffff} position={[1, 2, 1]} intensity={1} />
     

      <PerspectiveCamera makeDefault fov={fov} position={[0, 0, 2]} near={0.1} far={1000} />
      <Suspense fallback={null}>
        <Float>
          <CloneWf coneGeometryWf={coneGeometryWf} />
          <CloneS coneGeometryS={coneGeometryS} />
        </Float>
      </Suspense>
      <Preload all />
    </Canvas>

    </div>
   
  );
}

export default ConsultingSceneOpt2;

import React from 'react'
import './multiChain.css'
import crossChain1 from "../../assets/images/crossChain/Frame-polygon-brand.svg" 

 import crossChain3 from "../../assets/images/crossChain/cosmos.svg" 
// import crossChain4 from "../../assets/images/crossChain/filecoin-single.svg" 

import crossChain2 from "../../assets/images/crossChain/Frame-avail-brand.svg" 

import crossChain5 from "../../assets/images/crossChain/Frame-apex.svg" 

const MultiChain = () => {
  return (
    <section id='multiChain'>
        <div className='container multiChain_main'>
             <h2>Our Approach</h2>
               <div className='col-lg-6 col-md-8 col-sm-9 multiChain-content'>
                
                      Ethernal takes a <span className='no-wrap'>cross-chain</span> approach by collaborating with <a href="https://polygon.technology/" target="_blank" rel="noopener noreferrer">Polygon,
                      </a> <a href="https://www.availproject.org/" target="_blank" rel="noopener noreferrer">Avail,</a> <a href="https://cosmos.network/" target="_blank" rel="noopener noreferrer">Cosmos,</a> and <a href="https://www.apexfusion.org/" target="_blank" rel="noopener noreferrer">Apex Fusion</a> on the development of Web3 Technology.
                      {/* <a href="https://filecoin.io/" target="_blank" rel="noopener noreferrer">Filecoin</a> */}
                       
                
               
                </div>
                <div className='col-lg-6 col-md-8 col-sm-9 crossChain_main'>
                  <a href="https://polygon.technology/" target="_blank" rel="noopener noreferrer"><img src={crossChain1} alt="polygon" /></a>
                  <a href="https://www.availproject.org/" target="_blank" rel="noopener noreferrer"><img src={crossChain2} alt="avail" /></a>
                  <a href="https://cosmos.network/" target="_blank" rel="noopener noreferrer"><img src={crossChain3} alt="cosmos" /></a>
                  <a href="https://www.apexfusion.org/" target="_blank" rel="noopener noreferrer"><img src={crossChain5} alt="apexfusion" /></a>
                  {/* <a href="https://filecoin.io/" target="_blank" rel="noopener noreferrer"><img src={crossChain4} alt="filecoin" /></a> */}

                </div>

                <div className='col-lg-6 col-md-8 col-sm-9 multiChain-content-2'>
                
                      Through our collaborations with diverse leading blockchain projects we developed strong innovation capacity and ability for creating interoperability, synergies and cross-fertilization among various eco-systems.
                
               
                </div>
               
          
             {/* <div className='col-lg-3 col-md-3 col-sm-3 multiChain-btn'>
             <p><a href="https://polygon.technology/supernets" target="_blank" rel="noopener noreferrer" className='no-wrap'>???</a></p>
             </div> */}
            

        </div>
    </section>
  )
}

export default MultiChain
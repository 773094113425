import React from 'react';

 import PartnersMob from './PartnersMob';


import './partners.css';

import partner8 from "../../assets/images/partners/blade-blue.svg" 
import partner1 from "../../assets/images/partners/polygon-brand.svg" 
import partner2 from "../../assets/images/partners/Frame-informal.svg" 
import partner3 from "../../assets/images/partners/Frame-ponos.svg" 
import partner5 from "../../assets/images/partners/avail-brand.svg" 
import partner6 from "../../assets/images/partners/apex-figma.svg" 
import partner7 from "../../assets/images/partners/Frame-cosmos.svg" 


// import partner4 from "../../assets/images/partners/Frame-filecoin.svg" 

const Partners = () => {
  

  return (
     <section id='partners'>
         <div className='container partners-main'>

            {/* title for 5 partners - for apex */}
            {/* <div className='row partners-content' >
               <div className='col-2 partner partners-zero'>
            <h2 className='partners-title no-wrap'>Trusted by</h2>
         
          </div>

            </div> */}
         
          {/* row 1 */}
      <div className='row partners-content partners-content-first' >
         {/* comment live */}
         {/* <div className='col-2 partner partners-zero'>
            <h2 className='partners-title no-wrap'>Trusted by</h2>
         
          </div> */}


           <div className=' p-blade partner partners-first'>
           <a href="https://bladeblockchain.tech/" target="_blank" rel="noopener noreferrer" >
             <span className='partners-filter-main partners-img-relative'>
                <img 
                 src={partner8} 
                 alt="blade"
                 className={"partners-filter-img"}
                  />    
             </span>
           </a>
          </div> 
           

         <div className=' p-polygon partner partners-first'>
           <a href="https://polygon.technology/" target="_blank" rel="noopener noreferrer" >
             <span className='partners-filter-main partners-img-relative'>
                <img 
                 src={partner1} 
                 alt="polygon"
                 className={"partners-filter-img"}
                  />    
             </span>
           </a>
          </div> 

          

           <div className=' p-cosmos partner partners-first'>
           <a href="https://cosmos.network/" target="_blank" rel="noopener noreferrer" >
             <span className='partners-filter-main partners-img-relative'>
                <img 
                 src={partner7} 
                 alt="cosmos"
                 className={"partners-filter-img"}
                  />    
             </span>
           </a>
          </div> 

          

          <div className=' partner p-avail  partners-second' >
            <a href="https://www.availproject.org/" target="_blank" rel="noopener noreferrer" >
          <span className='partners-filter-main partners-img-relative'>
                <img 
                 src={partner5} 
                 alt="avail"
                 className={"partners-filter-img2"}
                  />    
             </span>
       </a>
      </div>
         
         


    </div>
       
       {/* row 2 */}
    <div className='row partners-content'>

         
 <div className=' partner p-informal partners-second' >
            <a href="https://informal.systems/" target="_blank" rel="noopener noreferrer" >
          <span className='partners-filter-main partners-img-relative'>
                <img 
                 src={partner2} 
                 alt="informal"
                 className={"partners-filter-img2"}
                  />    
             </span>
       </a>
      </div>
       

      <div className=' partner p-ponos partners-fourth' >
         <a href="https://ponos.technology/" target="_blank" rel="noopener noreferrer" >
               <span className='partners-filter-main partners-img-relative'>
                <img 
                 src={partner3} 
                 alt="ponos"
                 className={"partners-filter-img2"}
                  />    
             </span>
         </a>
      </div> 
          
         
       <div className=' partner p-apex' >
         <a href="https://www.apexfusion.org/" target="_blank" rel="noopener noreferrer" >
               <span className='partners-filter-main partners-img-relative'>
                <img 
                 src={partner6} 
                 alt="apexfusion"
                 className={"partners-filter-img2"}
                 
                  />    
             </span>
         </a>
      </div> 

          


        {/* <div className='partner partners-third' >
         <a href="https://filecoin.io/" target="_blank" rel="noopener noreferrer" >
               <span className='partners-filter-main partners-img-relative'>
                <img 
                 src={partner4} 
                 alt="filecoin"
                 className={"partners-filter-img"}
                  />    
             </span>
         </a>
      </div>  */}

    </div>

  

    <PartnersMob/>

   
        </div>

     </section>
  )
}

export default Partners


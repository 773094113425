import React from 'react'
import './footer.css';

const XtwitterIcon = () => {
  return (

        

         <svg className='footer-social-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 448"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M0,64V384a64,64,0,0,0,64,64H384a64,64,0,0,0,64-64V64A64,64,0,0,0,384,0H64A64,64,0,0,0,0,64ZM361,84,257.18,202.6,379.28,364h-95.6l-74.8-97.9L123.18,364H75.68l111-126.9L69.58,84h98l67.7,89.5L313.48,84ZM323.32,335,153.19,110H124.85l172,225h26.44Z"/></g></g></svg>

    
  )
}

export default XtwitterIcon
import React from 'react'
import './footer.css';

const FacebookIcon = () => {
  return (

        

        <svg className='footer-social-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 448"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M400,0H48A48,48,0,0,0,0,48V400a48,48,0,0,0,48,48H185V296H122V224h63V169.36c0-62.15,37.2-96.48,94.18-96.48,27.29,0,55.82,4.84,55.82,4.84V139H303.59C272.65,139,263,158.12,263,177.73V224h69.08L321,296H263V448H400a48,48,0,0,0,48-48V48A48,48,0,0,0,400,0Z"/></g></g></svg>

    
  )
}

export default FacebookIcon
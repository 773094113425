import React from 'react'
import { useInView } from 'react-intersection-observer';
import './header2.css'



const Header2 = () => {
  const { ref: h1Ref, inView: h1IsVisible } = useInView();
   const { ref: h1bRef, inView: h1bIsVisible } = useInView();
  // const { ref: h2Ref, inView: h2IsVisible } = useInView();
  return (
    <section id='header'>
      <div className='container header2-main'>
       <div className='row header2-row'>
        <div className='col-lg-12 col-md-12 col-sm-12 header2-col-title'>
               <div ref={h1Ref} className={ h1IsVisible ? 'header2-title-wrapper animation-header' : 'header2-title-wrapper'}>
            <h1 className= 'header2-title'>Ethernal builds core</h1>
            <h1 className='header2-title'>blockchain infrastructure</h1>
                    
            </div> 

            <div ref={h1bRef} className={ h1bIsVisible ? 'header2b-title-wrapper animation-header' : 'header2b-title-wrapper'}>
           
             <h1 className= 'header2b-title'>
              <span className='no-wrap'>Ethernal builds</span>
             <span className='no-wrap'>core blockchain</span>
             <span className='no-wrap'>infrastructure</span>
             </h1>
                    
            </div> 
          
        </div>

        
       </div>

      </div>
       
    </section>
  )
}

export default Header2

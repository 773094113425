//live
import React, {useRef, useMemo, useState, useEffect} from 'react'
import { Canvas, useFrame} from '@react-three/fiber'
  import {PerspectiveCamera, Float, Preload} from '@react-three/drei'
  import * as THREE from 'three'
   
import './careers2.css'

import {Model} from '../../components/modelWireframeEm/ModelDraco'


const widthDown = 2.0;
const heightDown = 2.0;
const offsetZDown = 1.0;
const rotationMatrixDown = new THREE.Matrix4().makeRotationX(THREE.MathUtils.degToRad(90));


const GeometryDOWN = (props) => {

  const { count, shape, isMoveAtBottom } = props;
  const pointsRef = useRef();
  const materialRef = useRef();

  const positions = useMemo(() => new Float32Array(count * 3), [count]);

  useEffect(() => {

    if (shape === 'planeDOWN') {
      for (let i = 0; i < count; i++) {
        let x = (Math.random() - 0.5) * widthDown;
        let y = (Math.random() - 0.5) * heightDown;
        let z = offsetZDown;
        const position = new THREE.Vector3(x, y, z);
        position.applyMatrix4(rotationMatrixDown);
        positions.set(position.toArray(), i * 3);
      }
      pointsRef.current.geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    }
  }, [count, shape, positions]);

  useFrame(() => {
    if (materialRef.current) {
      const newColor = isMoveAtBottom ? '#0072c3' : '#8a3ffc';
      
      if (materialRef.current.color.getHexString() !== newColor.replace('#', '')) {
        materialRef.current.color.set(newColor);
        
      }
    }
  });

  return (
    <points ref={pointsRef}>
      <bufferGeometry>
        <bufferAttribute
          attach="attributes.position"
          count={count}
          itemSize={3}
        />
      </bufferGeometry>
      
      <pointsMaterial
        ref={materialRef}
        size={0.008}
        sizeAttenuation
        depthWrite={false}
        
      />
      
    </points>
  );
};


const widthUp = 2.0;
const heightUp = 2.0;
const offsetZUp = -1.0;
const rotationMatrixUp = new THREE.Matrix4().makeRotationX(THREE.MathUtils.degToRad(90));

const GeometryUP = (props) => {

  const { count, shape, isMoveAtTop } = props;
  const pointsRef = useRef();
  const materialRef = useRef();

  const positions = useMemo(() => new Float32Array(count * 3), [count]);

  useEffect(() => {
    if (shape === 'planeUP') {
      for (let i = 0; i < count; i++) {
        let x = (Math.random() - 0.5) * widthUp;
        let y = (Math.random() - 0.5) * heightUp;
        let z = offsetZUp;
        const position = new THREE.Vector3(x, y, z);
        position.applyMatrix4(rotationMatrixUp);
        positions.set(position.toArray(), i * 3);
      }
      pointsRef.current.geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    }
  }, [count, shape, positions]);

  useFrame(() => {
    if (materialRef.current) {
      const newColor = isMoveAtTop ? '#0072c3' : '#8a3ffc';
      if (materialRef.current.color.getHexString() !== newColor.replace('#', '')) {
        materialRef.current.color.set(newColor);
      }
    }
  });

  return (
    <points ref={pointsRef}>
      <bufferGeometry>
        <bufferAttribute
          attach="attributes.position"
          count={count}
          itemSize={3}
        />
      </bufferGeometry>
      <pointsMaterial
        ref={materialRef}
        size={0.008}
        sizeAttenuation
        depthWrite={false}
      />
    </points>
  );
};


const GeometryMove1 = (props) => {

  const { count, shape, setIsMoveAtTop, setIsMoveAtBottom } = props;

  // This reference gives us direct access to our points
  const pointsRef = useRef();

  // Generate our positions attributes array
  useEffect(() => {

    const positions = new Float32Array(count * 3);
    // ... (ostatak koda za generisanje pozicija ostaje isti)//

const width = 2.0;
  const height = 2.0;
  const edgeCount = 100; // Broj čestica na ivicama
  const particleCount = edgeCount * 4; // Ukupan broj čestica

  const rotationMatrix = new THREE.Matrix4().makeRotationX(THREE.MathUtils.degToRad(90));

  for (let i = 0; i < edgeCount; i++) {
    const t = i / (edgeCount - 1);
    const x = THREE.MathUtils.lerp(-width / 2, width / 2, t);
    const y = THREE.MathUtils.lerp(-height / 2, height / 2, t);
    const position = new THREE.Vector3(x, -height / 2, 0);
    position.applyMatrix4(rotationMatrix);
    positions.set(position.toArray(), i * 3); // Donja ivica

    position.set(x, height / 2, 0);
    position.applyMatrix4(rotationMatrix);
    positions.set(position.toArray(), (i + edgeCount) * 3); // Gornja ivica

    position.set(-width / 2, y, 0);
    position.applyMatrix4(rotationMatrix);
    positions.set(position.toArray(), (i + edgeCount * 2) * 3); // Leva ivica

    position.set(width / 2, y, 0);
    position.applyMatrix4(rotationMatrix);
    positions.set(position.toArray(), (i + edgeCount * 3) * 3); // Desna ivica
  }

  // Generišemo slučajne pozicije unutar ravne površine za preostale čestice
  for (let i = edgeCount * 4; i < particleCount; i++) {
    let x = (Math.random() - 0.5) * width;
    let y = (Math.random() - 0.5) * height;
    let z = 0; // Pretpostavljamo da je ravna površina 2D, pa je z-koordinata 0

    const position = new THREE.Vector3(x, y, z);
    position.applyMatrix4(rotationMatrix);
    positions.set(position.toArray(), i * 3);
  }

    //////////////////////////////////

    if (pointsRef.current) {
      pointsRef.current.geometry.setAttribute(
        'position',
        new THREE.BufferAttribute(positions, 3)
      );
    }
  }, [count, shape]);

  // Animation logic
  useFrame(({ clock }) => {
    if (shape === "planeMOVE" && pointsRef.current) {
      const time = clock.getElapsedTime();
      const speedFactor = 0.2;
      const t = (Math.sin(time * speedFactor) + 1) / 2;
      const positions = pointsRef.current.geometry.attributes.position.array;
      
      for (let i = 0; i < count; i++) {
        const index = i * 3;
        const startY = -1.0;
        const endY = 1.0;
        positions[index + 1] = THREE.MathUtils.lerp(startY, endY, t);
      }

      setIsMoveAtTop(positions[1] >= 0.95 || positions[1] <= -0.95);
      setIsMoveAtBottom(positions[1] >= 0.95 || positions[1] <= -0.95);
      pointsRef.current.geometry.attributes.position.needsUpdate = true;
    }
  });

  return (
    <points ref={pointsRef}>
      <bufferGeometry>
        <bufferAttribute
          attach="attributes.position"
          count={count}
          itemSize={3}
        />
      </bufferGeometry>
      <pointsMaterial 
        size={0.006} 
        color="#0072c3" 
        sizeAttenuation 
        depthWrite={false}
      />
    </points>
  );
};


const GeometryMove2 = (props) => {


  const { count, shape, setIsMoveAtTop, setIsMoveAtBottom } = props;
  const points = useRef();

  const particlesPosition = useMemo(() => {
    const positions = new Float32Array(count * 3);

    if (shape === "planeMOVE") {
      const width = 2.0;
      const height = 2.0;
      const edgeCount = 100;
      const particleCount = edgeCount * 4;
      const rotationMatrix = new THREE.Matrix4().makeRotationX(THREE.MathUtils.degToRad(90));

      // ... (Ostatak koda za postavljanje početnih pozicija) //
      for (let i = 0; i < edgeCount; i++) {
    const t = i / (edgeCount - 1);
    const x = THREE.MathUtils.lerp(-width / 2, width / 2, t);
    const y = THREE.MathUtils.lerp(-height / 2, height / 2, t);
    const position = new THREE.Vector3(x, -height / 2, 0);
    position.applyMatrix4(rotationMatrix);
    positions.set(position.toArray(), i * 3); // Donja ivica

    position.set(x, height / 2, 0);
    position.applyMatrix4(rotationMatrix);
    positions.set(position.toArray(), (i + edgeCount) * 3); // Gornja ivica

    position.set(-width / 2, y, 0);
    position.applyMatrix4(rotationMatrix);
    positions.set(position.toArray(), (i + edgeCount * 2) * 3); // Leva ivica

    position.set(width / 2, y, 0);
    position.applyMatrix4(rotationMatrix);
    positions.set(position.toArray(), (i + edgeCount * 3) * 3); // Desna ivica
  }

  // Generišemo slučajne pozicije unutar ravne površine za preostale čestice
  for (let i = edgeCount * 4; i < particleCount; i++) {
    let x = (Math.random() - 0.5) * width;
    let y = (Math.random() - 0.5) * height;
    let z = 0; // Pretpostavljamo da je ravna površina 2D, pa je z-koordinata 0

    const position = new THREE.Vector3(x, y, z);
    position.applyMatrix4(rotationMatrix);
    positions.set(position.toArray(), i * 3);
  }
      //////////////////////////////////////
    }

    return positions;
  }, [count, shape]);

  useFrame(({ clock }) => {
    const time = clock.getElapsedTime();
    const speedFactor = 0.2;
    const t = (Math.sin(time * speedFactor) + 1) / 2;

    if (shape === "planeMOVE") {
      for (let i = 0; i < count; i++) {
        const index = i * 3;
        const startY = 1.0;
        const endY = -1.0;
        particlesPosition[index + 1] = THREE.MathUtils.lerp(startY, endY, t);
      }

      const condition = particlesPosition[1] >= 0.95 || particlesPosition[1] <= -0.95;

      setIsMoveAtTop(prev => (prev !== condition ? condition : prev));
      setIsMoveAtBottom(prev => (prev !== condition ? condition : prev));
    }

    points.current.geometry.attributes.position.needsUpdate = true;
  });

  return (
    <points ref={points}>
      <bufferGeometry>
        <bufferAttribute
          attach="attributes-position"
          count={particlesPosition.length / 3}
          array={particlesPosition}
          itemSize={3}
        />
      </bufferGeometry>
      <pointsMaterial
        size={0.006}
        color="#0072c3"
        sizeAttenuation
        depthWrite={false}
      />
    </points>
  );
};


function HeaderSceneOpt3() {

     const [isMoveAtTop, setIsMoveAtTop] = useState(false);
  const [isMoveAtBottom, setIsMoveAtBottom] = useState(false);


  return (
    <div className='header_canvas_wrapper'>
     
       <Canvas pixelRatio={2} invalidateFrameloop>
              
     
         <ambientLight color={0xffffff} intensity={1}/>
         <directionalLight color={0xffffff} position={[0,0,2]} intensity={1}  /> 
          <directionalLight color={0xffffff} position={[0,-2,0]} intensity={1}  /> 
         <directionalLight color={0xffffff} position={[0,2,0]} intensity={1}  /> 
         <spotLight color={0xffffff} position={[0, 0, 2]} angle={Math.PI/2}  intensity={1} />

         

        <PerspectiveCamera makeDefault fov={90} position={[0,0,2]}/>
        
            
               <Float >
            <GeometryDOWN   count={2000} shape="planeDOWN"  isMoveAtBottom={isMoveAtBottom}  />
           <GeometryUP   count={2000} shape="planeUP" isMoveAtTop={isMoveAtTop}  />
            <Model  />
            <GeometryMove1 count={1000} shape="planeMOVE"  setIsMoveAtTop={setIsMoveAtTop} setIsMoveAtBottom={setIsMoveAtBottom}  /> 
            <GeometryMove2 count={1000} shape="planeMOVE"  setIsMoveAtTop={setIsMoveAtTop} setIsMoveAtBottom={setIsMoveAtBottom}  />  
           
             </Float>

       <Preload all/>
     </Canvas>

    </div>
    
  )
}

export default HeaderSceneOpt3;
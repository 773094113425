import React from 'react'

export const NewsCard = (props) => {
  return (
     <div className='card news-card-style item'>
             <a href={props.link} target="_blank" rel="noopener noreferrer" className="card-link"> 
             
                <img src={`../news/${props.img}`} className="card-img-top" alt={props.content}/>
                 
             <div className="card-body">
                <div className='card-time'>
             
              <p className='card-topic'>{props.topic}</p>

             </div> 
            
            <p className="card-text">{props.content}</p>
             <div className='card-time'>
              <p className='card-date'>{props.date}</p>
              

             </div>
            
            </div>
           
              </a>
             </div>
  )
}

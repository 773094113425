import React from 'react'

import './partners.css';
import './partnersMob.css';

import partner8 from "../../assets/images/partners/blade-blue.svg" 
 import partner1 from "../../assets/images/partners/polygon-brand.svg" 
import partner2 from "../../assets/images/partners/Frame-informal.svg"
import partner3 from "../../assets/images/partners/Frame-ponos.svg" 
import partner5 from "../../assets/images/partners/avail-brand.svg" 
 import partner6 from "../../assets/images/partners/apex-figma.svg" 
 import partner7 from "../../assets/images/partners/Frame-cosmos.svg" 
 
// import partner4 from "../../assets/images/partners/Frame-filecoin.svg" 

const PartnersMob = () => {
  return (
    <section className='partners-section-mob'>

        {/* <div className='partners-mob-title'>

             <h2 className='no-wrap'>Our Partners</h2>

        </div> */}

                  {/* new row */}
        
     {/* <div className='row partners-mob-row-title'>
      
     <div className='col-5 partners-mob-title'>

             <h2 className='no-wrap'>Trusted by</h2>

        </div> 
    <div className='col-7'></div>
    

    </div>  */}

{/* row 1 */}
 <div className='row partners-mob-row'>

   <div className='col-5 partner-mob-col  mob-a-first'>
           <a className='mob-a ' href="https://bladeblockchain.tech/" target="_blank" rel="noopener noreferrer" >
             <span className='partners-filter-main partners-img-relative'>
                <img 
                 src={partner8} 
                 alt="blade"
                 className={"partners-filter-imgMob blade-img"}
                  />    
             </span>
           </a>
     </div>
     

     <div className='col-5 partner-mob-col mob-a-first'>
           <a className='mob-a ' href="https://polygon.technology/" target="_blank" rel="noopener noreferrer" >
             <span className='partners-filter-main partners-img-relative'>
                <img 
                 src={partner1} 
                 alt="polygon"
                 className={"partners-filter-imgMob"}
                  />    
             </span>
           </a>
     </div>

    </div>

  {/* row 2 */}
    <div className='row partners-mob-row'>

      <div className='col-5 partner-mob-col mob-a-second'>
            <a className='mob-a ' href="https://cosmos.network/" target="_blank" rel="noopener noreferrer" >
               <span className='partners-filter-main partners-img-relative'>
                <img 
                 src={partner7} 
                 alt="cosmos"
                 className={"partners-filter-imgMob"}
                  />    
             </span>
         </a>
     </div>


        <div className='col-5 partner-mob-col mob-a-second'>
           <a className='mob-a ' href="https://www.availproject.org/" target="_blank" rel="noopener noreferrer" >
               <span className='partners-filter-main partners-img-relative'>
                <img 
                 src={partner5} 
                 alt="avail"
                 className={"partners-filter-imgMob"}
                  />    
             </span>
         </a>
     </div>

      
    </div>

  {/* row 3 */}
     <div className='row partners-mob-row'>

       <div className='col-5 partner-mob-col mob-a-second'>
            <a className='mob-a ' href="https://informal.systems/" target="_blank" rel="noopener noreferrer" >
          <span className='partners-filter-main partners-img-relative'>
                <img 
                 src={partner2} 
                 alt="informal"
                 className={"partners-filter-imgMob"}
                  />    
             </span>
       </a>
     </div>

       <div className='col-5 partner-mob-col mob-a-second '>
            <a className='mob-a ' href="https://ponos.technology/" target="_blank" rel="noopener noreferrer" >
               <span className='partners-filter-main partners-img-relative'>
                <img 
                 src={partner3} 
                 alt="ponos"
                 className={"partners-filter-imgMob"}
                  />    
             </span>
         </a>
     </div>

      
    
    </div>

  {/* row 4 */}
    <div className='row partners-mob-row'>
      <div className='col-6 partner-mob-col mob-a-last '>
            <a className='mob-a ' href="https://www.apexfusion.org/" target="_blank" rel="noopener noreferrer" >
               <span className='partners-filter-main partners-img-relative'>
                <img 
                 src={partner6} 
                 alt="apexfusion"
                 className={"partners-filter-imgMob2"}
                  />    
             </span>
         </a>
     </div>
     <div className='col-4'>

     </div>

    </div>

  
  
    </section>
    
  )
}

export default PartnersMob
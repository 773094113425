import React from 'react'
import './footer.css';

const LinkedInIcon = () => {
  return (

        
<svg className='footer-social-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 448"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M416,0H31.9A32.14,32.14,0,0,0,0,32.3V415.7A32.14,32.14,0,0,0,31.9,448H416a32.22,32.22,0,0,0,32-32.3V32.3A32.22,32.22,0,0,0,416,0ZM134.9,384H68V170h67V384ZM101.83,141.21a38.5,38.5,0,1,1,38.5-38.5A38.52,38.52,0,0,1,101.83,141.21ZM384,384H317V280c0-24.8-.5-56.7-34.31-56.7-34.42,0-39.69,27-39.69,54.9V384H177V170h64v29h.9c8.87-16.84,30.49-34.57,62.68-34.57,67,0,79.42,44.39,79.42,102.12Z"/></g></g></svg>
        

    
  )
}

export default LinkedInIcon
/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.4 modelDraco.gltf --transform
*/

import React from 'react'
// , { useRef }
import { useGLTF } from '@react-three/drei'



export function Model(props) {
  const { nodes } = useGLTF('/modelWireframeEm/modelDraco.gltf')
  // , materials
  
  return (
    <group {...props} dispose={null}>
    
    {/* material={materials.PaletteMaterial002} */}
      <mesh geometry={nodes.logo2.geometry}  rotation={[Math.PI / 2, 0, 0]} scale={32
      } >
         
         <meshPhysicalMaterial
          color="#c0c0c0"
          
          roughness={0.2}
          metalness={0.9}
         
          wireframe
          
        />
       
      </mesh>
     
    </group>
  )
}

useGLTF.preload('/modelWireframeEm/modelDraco.gltf')

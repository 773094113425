import React from 'react';

import './team.css';
import team1 from './dataTeam1';
import team2 from './dataTeam2';
import { Team1Img } from './Team1Img';
import { Team2Img } from './Team2Img';




const Team = () => {
 const images1 = team1.map(image =>{

  return <Team1Img img={image.img} name={image.name} position={image.position} link={image.link} key={image.id} />

 })

 const images2 = team2.map(image =>{

  return <Team2Img img={image.img} name={image.name} link={image.link} key={image.id} />

 })

  return (
    
    <section id="team" className="team-team">
      <div className="container gallery-main">
       
         {/* gallery-1 */}
        <div className="row gallery-content1">

          {images1}

        </div>

         {/* gallery-2 */}

          <div className="row gallery-content2">
           {images2}

        </div>
        
      </div>
    </section>
    

  )
}

export default Team
